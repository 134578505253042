<template>
  <div class="pubMask">
    <el-dialog :visible.sync="isShowMask" :close-on-press-escape="false" :close-on-click-modal="false"
      :width="propShowSwitch == 4 ? '850px' : '600px'">
      <div class="maskMain">
        <img class="closed" @click="closeMask" :src="closed" alt="">
        <div v-if="forgetWhichFlag == 1 || forgetWhichFlag == 2" class="title">{{ findPswTitle }}</div>
        <div v-if="showSwitch == 1" class="mobileWrap changeInfo">
          <div v-if="forgetWhichFlag == 0" class="forgetWrap">
            <div class="forgetItem forgetUserBtn" @click="forgetWhich(1, '忘记账号')">忘记账号</div>
            <div class="forgetItem forgetPswBtn" @click="forgetWhich(2, '忘记密码')">忘记密码</div>
            <div class="forgetItem forgetPswBtn" @click="reset">忘记手机号</div>
          </div>
          <el-form v-if="forgetWhichFlag == 1 || forgetWhichFlag == 2" ref="findPswForm"
            :model="forgetWhichFlag == 1 ? findPswFormUser : findPswForm"
            :rules="forgetWhichFlag == 1 ? findPswFormRulesUser : findPswFormRules" label-width="100px">
            <input type="text" style="position: absolute;z-index:-999;left:-99999px;" />
            <input type="password" style="position: absolute;z-index:-999;left:-99999px;" />

            <el-form-item v-if="forgetWhichFlag == 2" label="游戏账号:" prop="username">
              <el-input v-model="findPswForm.username" placeholder="由6~12位数字或字母组成"></el-input>
            </el-form-item>

            <el-form-item label="手机号码:" class="telWrap" prop="mobile">
              <template v-if="forgetWhichFlag == 2">
                <el-select @change="changeArea(findPswForm.area)" class="Sel" v-model="findPswForm.area"
                  placeholder="Select" style="width: 25%">
                  <el-option style="width: 250px;" v-for="item in cities" :key="item.label" :label="item.value"
                    :value="item.valuee">
                    <span style="float: left">{{ item.label }}</span>
                    <span
                      style="float: right;color: var(--el-text-color-secondary);font-size: 13px;margin-right: 40px;">
                      {{ item.value }}
                    </span>
                  </el-option>
                </el-select>
                <el-input style="width: 51%;" ref="findPswMobile" v-model="findPswForm.mobile"
                  placeholder="输入绑定的手机号"></el-input>
              </template>
              <template v-if="forgetWhichFlag == 1">
                <el-select @change="changeArea(findPswFormUser.area)" class="Sel" v-model="findPswFormUser.area"
                  placeholder="Select" style="width: 25%">
                  <el-option style="width: 250px;" v-for="item in cities" :key="item.label" :label="item.value"
                    :value="item.valuee">
                    <span style="float: left">{{ item.label }}</span>
                    <span
                      style="float: right;color: var(--el-text-color-secondary);font-size: 13px;margin-right: 40px;">
                      {{ item.value }}
                    </span>
                  </el-option>
                </el-select>
                <el-input style="width: 51%;" ref="findPswMobile" v-model="findPswFormUser.mobile"
                  placeholder="输入绑定的手机号"></el-input>
              </template>
            </el-form-item>


            <el-form-item label="短信验证:" class="codeWrap" prop="yzm">
              <div class="codeBar">
                <template v-if="forgetWhichFlag == 2"><el-input v-model="findPswForm.yzmCode"
                    placeholder="验证码"></el-input></template>
                <template v-if="forgetWhichFlag == 1"><el-input v-model="findPswFormUser.yzmCode"
                    placeholder="验证码"></el-input></template>

                <template v-if="!isSendCodefindPsw">
                  <template v-if="forgetWhichFlag == 2"><span class="sendBtn"
                      @click="noLoginGetYzm(findPswForm.mobile, 2, findPswForm.area)">{{ yzmTxtFindPsw
                      }}</span></template>
                  <template v-if="forgetWhichFlag == 1"><span class="sendBtn"
                      @click="noLoginGetYzm(findPswFormUser.mobile, 7, findPswFormUser.area)">{{ yzmTxtFindPsw
                      }}</span></template>
                </template>
                <span class="sendBtn sendBtn1" v-else>{{ timerCountfindPsw }}s</span>
              </div>
            </el-form-item>


            <el-form-item v-if="forgetWhichFlag == 2" label="输入密码:" prop="newPwd">
              <el-input type="password" v-model="findPswForm.newPwd" placeholder="由6~18位数字、字母、字符组成"></el-input>
            </el-form-item>

            <el-form-item v-if="forgetWhichFlag == 2" label="确认密码:" prop="confirmPwd">
              <el-input type="password" v-model="findPswForm.confirmPwd" placeholder="由6~18位数字、字母、字符组成"></el-input>
            </el-form-item>

            <el-form-item class="submitWrapBar">
              <div class="submitWrap">
                <div class="btnWrap" @click="submitPsw('findPswForm')"><span>确定找回</span></div>
                <div class="btnWrap cancel" @click="closeMask"><span>取消找回</span></div>
              </div>
            </el-form-item>
          </el-form>

        </div> <!-- 忘记密码 - 忘记密码 -->
        <div v-if="showSwitch == 2" class="mobileWrap changeInfo">
          <el-form ref="mobileForm" :model="mobileForm" :rules="mobileFormRules" label-width="100px">
            <input type="text" style="position: absolute;z-index:-999;left:-99999px;" />
            <input type="password" style="position: absolute;z-index:-999;left:-99999px;" />
            <el-form-item label="原手机号:" class="telWrap" prop="mobile"><el-input ref="yuanMobie" :disabled="true"
                v-model="mobileForm.mobile" placeholder="输入绑定的手机号"></el-input></el-form-item>
            <el-form-item label="短信验证:" class="codeWrap" prop="yzm">
              <div class="codeBar">
                <el-input v-model="mobileForm.yzmCode" placeholder="验证码"></el-input>
                <span class="sendBtn" @click="getCode(mobileForm.mobile, 4)" v-if="!isSendCode">{{ yzmTxtYuanMobile
                  }}</span>
                <span class="sendBtn sendBtn1" v-else>{{ timerCount }}s</span>
              </div>
            </el-form-item>
            <el-form-item label="新手机号:" class="telWrap" prop="newMobile"><el-input ref="newMobile"
                v-model="mobileForm.newMobile" placeholder="请输入新手机号"></el-input></el-form-item>
            <el-form-item label="短信验证:" class="codeWrap" prop="yzm">
              <div class="codeBar">
                <el-input v-model="mobileForm.newYzmCode" placeholder="验证码"></el-input>
                <span class="sendBtn" @click="getCode(mobileForm.newMobile, 5)" v-if="!isSendCode1">{{ yzmTxtNewMobile
                  }}</span>
                <span class="sendBtn sendBtn1" v-else>{{ timerCount1 }}s</span>
              </div>
            </el-form-item>
            <el-form-item class="submitWrapBar">
              <div class="submitWrap">
                <div class="btnWrap" @click="submitForm('mobileForm')"><span>确定修改</span></div>
                <div class="btnWrap cancel" @click="closeMask"><span>取消修改</span></div>
              </div>
            </el-form-item>
          </el-form>
        </div> <!-- 修改手机号 -->
        <div v-if="showSwitch == 3" class="mobileWrap changeInfo">
          <el-form ref="modifyPswForm" :model="modifyPswForm" :rules="modifyPswFormRules" label-width="120px">
            <input type="text" style="position: absolute;z-index:-999;left:-99999px;" />
            <input type="password" style="position: absolute;z-index:-999;left:-99999px;" />

            <el-form-item label="手机号码:" class="telWrap" prop="mobile"><el-input ref="modifyPswMobile"
                v-model="modifyPswForm.mobile" placeholder="输入绑定的手机号"></el-input></el-form-item>
            <el-form-item label="短信验证:" class="codeWrap" prop="yzm">
              <div class="codeBar">
                <el-input v-model="modifyPswForm.yzmCode" placeholder="验证码"></el-input>
                <span class="sendBtn" @click="getCode(modifyPswForm.mobile, 3)" v-if="!modifyPswFormIsSendCode">{{
                  modifyPswFormYzmTxt }}</span>
                <span class="sendBtn sendBtn1" v-else>{{ modifyPswFormTimerCount }}s</span>
              </div>
            </el-form-item>
            <el-form-item label="输入新密码:" prop="newPwd"><el-input type="password" v-model="modifyPswForm.newPwd"
                placeholder="由6~18位数字、字母、字符组成"></el-input></el-form-item>
            <el-form-item label="确认新密码:" prop="confirmPwd"><el-input type="password" v-model="modifyPswForm.confirmPwd"
                placeholder="由6~18位数字、字母、字符组成"></el-input></el-form-item>
            <el-form-item class="submitWrapBar">
              <div class="submitWrap">
                <div class="btnWrap" @click="submitmodifyPsw('modifyPswForm')"><span>确定修改</span></div>
                <div class="btnWrap cancel" @click="closeMask"><span>取消修改</span></div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 修改密码 -->
        <div v-if="showSwitch == 4" class="vip">
          <p class="txt1">
            <span class="num">1.</span>
            <span>用户达到相应VIP等级以后可领取以下VIP福利（奖励可累计获取但每种仅限一次）：</span>
          </p>
          <p class="txt2">VIP1(累计获取6积分)：幸运钥匙X10。</p>
          <p class="txt2">VIP2(累计获取30积分): 幸运钥匙X20。</p>
          <p class="txt2">VIP3(累计获取128积分)：幸运钥匙X50。</p>
          <p class="txt2">VIP4(累计获取328积分)：幸运钥匙X100；VIP4专属徽章。</p>
          <p class="txt2">VIP5(累计获取648积分)：幸运钥匙X200；VIP5专属徽章。</p>
          <p class="txt2">VIP6(累计获取1188积分): 幸运钥匙X300；VIP6专属徽章。</p>
          <p class="txt2">VIP7(累计获取3000积分)：幸运钥匙X400；服饰兑换券X500；VIP7专属徽章。</p>
          <p class="txt2">VIP8(累计获取6000积分)：幸运钥匙X600；服饰兑换券X1000；VIP8专属徽章。</p>
          <p class="txt2">VIP9(累计获取12000积分)：幸运钥匙X800；服饰兑换券X1500；VIP9专属徽章。</p>
          <p class="txt2">VIP10(累计获取30000积分):30000积分/幸运钥匙X1000；服饰兑换券X2000；VIP10专属徽章。</p>
          <p class="txt1">
            <span class="num">2.</span>
            <span>所有VIP7及以上用户每周可免费获得VIP回馈礼包一个，内含幸运钥匙X7。每周一上午10点发放，请于网站商城—幸运盲盒中查收。</span>
          </p>
          <p class="txt1">
            <span class="num">3.</span>
            <span>LV8及以上VIP用户可定制专属生日祝福。(请提前至少七个工作联系客服，定制内容需要符合相关法律法规，每年限一次)。</span>
          </p>
          <p class="txt1">
            <span class="num">4.</span>
            <span>VIP10用户可获得私人定制一次，用户可定制全套专属私人服饰，详情请联系客服咨询，全套服饰制作周期大约需要40个工作日左右。</span>
          </p>
        </div> <!-- VIP说明 -->
        <div v-if="showSwitch == 5" class="tipCopywriting">
          <div class="tipCopywritingMain">{{ tipCon }}</div>
          <div class="submitWrap">
            <div class="btnWrap" @click="submitTip"><span>确定</span></div>
            <div class="btnWrap cancel" @click="closeMask"><span>取消</span></div>
          </div>
        </div>

        <div v-if="showSwitch == 6" class="tipCopywriting">
          <div class="tipCopywritingMain" style="font-size: 22px;font-weight: 400;margin-bottom: 0px;">扫码加入主播家族微信群</div>
          <div class="tipCopywritingMain" style="font-size: 24px;color:#FF0000;font-weight: 400;margin-bottom: 13px;">
            领取家族专属福利
          </div>
          <div class="tipCopywritingMain">
            <img :src="title" alt="" width="200" height="266">
          </div>
        </div>

        <!-- 修改游戏昵称 -->
        <div v-if="showSwitch == 7" class="mobileWrap changeInfo">
          <el-form ref="modifyNicknameForm" :model="modifyNicknameForm" :rules="modifyPswFormRules" label-width="120px">
            <input type="text" style="position: absolute;z-index:-999;left:-99999px;" />
            <input type="password" style="position: absolute;z-index:-999;left:-99999px;" />

            <el-form-item label="手机号码:" class="telWrap" prop="mobile"><el-input ref="modifyPswMobile"
                v-model="modifyNicknameForm.mobile" placeholder="输入绑定的手机号"></el-input></el-form-item>
            <el-form-item label="短信验证:" class="codeWrap" prop="yzm">
              <div class="codeBar">
                <el-input v-model="modifyNicknameForm.yzmCode" placeholder="验证码"></el-input>
                <span class="sendBtn" @click="getCode(modifyNicknameForm.mobile, 8)" v-if="!isSendCode1">{{
                  modifyPswFormYzmTxt }}</span>
                <span class="sendBtn sendBtn1" v-else>{{ timerCount1 }}s</span>
              </div>
            </el-form-item>
            <el-form-item label="输入新昵称:" prop="newUsernick"><el-input type="text"
                v-model="modifyNicknameForm.newUsernick" placeholder="请输入游戏昵称"></el-input></el-form-item>
            <el-form-item label="确认新昵称:" prop="confirmUsernick"><el-input type="text"
                v-model="modifyNicknameForm.confirmUsernick" placeholder="请输入游戏昵称"></el-input></el-form-item>
            <el-form-item label="注:">
              <div>
                本次操作将扣除20000泡点
              </div>
            </el-form-item>
            <el-form-item class="submitWrapBar">
              <div class="submitWrap">
                <div class="btnWrap" @click="submitmodifyNickname('modifyNicknameForm')"><span>确定修改</span></div>
                <!-- <div class="btnWrap" @click="su"><span>确定修改</span></div> -->
                <div class="btnWrap cancel" @click="closeMask"><span>取消修改</span></div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 实名 -->
        <div v-if="showSwitch == 8" class="mobileWrap changeInfo">
          <el-form ref="realName" :model="realName" :rules="realNameFormRules" label-width="100px">
            <input type="text" style="position: absolute;z-index:-999;left:-99999px;" />
            <input type="password" style="position: absolute;z-index:-999;left:-99999px;" />
            <el-form-item label="姓名:" class="telWrap" prop="name"><el-input ref="name" v-model="realName.name"
                placeholder="输入姓名"></el-input></el-form-item>

            <el-form-item label="身份证:" class="telWrap" prop="nameId"><el-input ref="nameId" v-model="realName.nameId"
                placeholder="请输入身份证号"></el-input></el-form-item>

            <el-form-item class="submitWrapBar">
              <div class="submitWrap">
                <div class="btnWrap" @click="realNameForm('realName')"><span>确定实名</span></div>
                <div class="btnWrap cancel" @click="closeMask"><span>取消实名</span></div>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <!-- 忘记手机号 -->
        <div v-if="showSwitch == 9" class="mobileWrap changeInfo" style="text-align: center;">
          <div class="title">忘记手机号</div>
          <div style="font-size: 22px;line-height: 50px;">如您的绑定手机已经无法正常使用，请联系通过企业QQ或者微信联系在线客服人工处理。</div>
          <a target="_blank" href="tencent://message/?uin=3003533756&Site=wx.qtthtech.com&Menu=yes">
            <img border="0" style="margin: auto; margin-top: 30px;" src="@/assets/imgs/menu/qqIcon.png" alt="点击这里给我发消息"
              title="点击这里给我发消息" />
          </a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import eventBus from '@/common/bus.js'
import Messages from "@/common/Message/index.js";

export default {
  name: 'pubMask',
  props: {
    isShowPask: false,
    propShowSwitch: 0,
    title: '',
    yuanMobileProp: '',          // 修改手机号时：原手机号
    tipCon: '',                  // 文案提示信息
  },
  data() {
    return {
      isShowMask: this.isShowPask,
      /*  找回密码*/
      findPswTitle: '',
      forgetWhichFlag: 0,
      findPswForm: {
        area: '86',                // 区号
        username: '',            // 用户名
        mobile: '',              // 手机号
        yzmCode: '',             // 验证码
        newPwd: '',              // 新密码
        confirmPwd: '',          // 重复新密码
      },
      cities: [
        {
          value: '+86',
          valuee: '86',
          label: '中国大陆',
        },
        {
          value: '+886',
          valuee: '886',
          label: '中国台湾',
        },
        {
          value: '+852',
          valuee: '852',
          label: '中国香港',
        },
        {
          value: '+853',
          valuee: '853',
          label: '中国澳门',
        }
      ],
      realName: {        // 实名
        name: '',
        nameId: ''
      },
      realNameFormRules: {
        name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { required: true, pattern: /^[\u4e00-\u9fa5]{2,4}$/, message: '真实姓名由2-4位汉字组成', trigger: 'blur' }
        ],
        nameId: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { required: true, pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号', trigger: 'blur' }
        ],

      },
      findPswFormUser: {
        area: '86',                // 区号
        mobile: '',              // 手机号
        yzmCode: '',             // 验证码
      },

      findPswFormRules: {
        username: [
          { required: true, message: '请输入游戏账号', trigger: 'blur' },
          { required: true, pattern: /^([0-9A-Za-z]){6,12}$/, message: '游戏账号由6~12位数字或字母组成', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        yzmCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { required: true, pattern: /^[0-9]{6}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, pattern: /^[\w!@#$%^&*()-+=<>?]{6,18}$/i, message: '密码由6~18位数字、字母、字符组成', trigger: 'blur' }
        ],
        confirmPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, pattern: /^[\w!@#$%^&*()-+=<>?]{6,18}$/i, message: '密码由6~18位数字、字母、字符组成', trigger: 'blur' }
        ],
      },
      findPswFormRulesUser: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        yzmCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { required: true, pattern: /^[0-9]{6}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
      },

      timerfindPsw: null,        // 忘记密码定时器
      isSendCodefindPsw: false,  // 是否已经发送验证码
      timerCountfindPsw: 60,
      yzmTxtFindPsw: '免费获取',

      /*  修改手机号 */
      mobileForm: {
        mobile: this.yuanMobileProp,      // 手机号
        yzmCode: '',     // 原手机号验证码
        newMobile: '',   // 新手机号
        newYzmCode: '',  // 新手机号验证码
        platForm: 'web',
        userId: Cookies.get('wx_userId'),      // userId
      },
      mobileFormRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        yzmCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        newMobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        newYzmCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      timer: null,       // 原手机号倒计时
      isSendCode: false, // 原手机号倒计时
      timerCount: 60,
      yzmTxtYuanMobile: '免费获取',

      timer1: null,      // 新手机号倒计时
      isSendCode1: false,
      timerCount1: 60,
      yzmTxtNewMobile: '免费获取',

      /* 修改密码 */
      modifyPswForm: {
        mobile: '',              // 手机号
        yzmCode: '',             // 验证码
        newPwd: '',              // 新密码
        confirmPwd: '',          // 重复新密码
      },
      /* 修改游戏昵称 */
      modifyNicknameForm: {
        userId: "",
        mobile: '',              // 手机号
        yzmCode: '',             // 验证码
        newUsernick: '',              // 新密码
        confirmUsernick: '',          // 重复新密码
      },
      modifyPswFormRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        yzmCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { required: true, pattern: /^[0-9]{6}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, pattern: /^[\w!@#$%^&*()-+=<>?]{6,18}$/i, message: '密码由6~18位数字、字母、字符组成', trigger: 'blur' }
        ],
        confirmPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, pattern: /^[\w!@#$%^&*()-+=<>?]{6,18}$/i, message: '密码由6~18位数字、字母、字符组成', trigger: 'blur' }
        ],
      },
      modifyPswFormTimer: null,        // 修改密码定时器
      modifyPswFormIsSendCode: false,  // 是否已经发送验证码
      modifyPswFormTimerCount: 60,
      modifyPswFormYzmTxt: '免费获取',

      showSwitch: this.propShowSwitch,
      closed: require('@/assets/imgs/public/close.png'),
      imgCustomer: "",
      userInfo: {
        verifyInfo: {},
        vipInfo: {},
        wallteInfo: {},
      },            // 用户信息
    };
  },
  created() {
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    console.log(this.userInfo, '用户信息');

    if (this.userInfo) {
      if (this.userInfo.area == '886') {
        this.modifyPswFormRules.mobile[1].pattern = /^09\d{8}$/;
      } else if (this.userInfo.area == '852') {
        this.modifyPswFormRules.mobile[1].pattern = /^[4|5|6|8|9]\d{7}$/;
      } else if (this.userInfo.area == '853') {
        this.modifyPswFormRules.mobile[1].pattern = /^6\d{7}$/;
      }
    }

    // console.log(this.userInfo.area, this.modifyPswFormRules.mobile[1].pattern );

    this.init()
  },
  methods: {

    submitTip() {
      this.$emit('determine')
    },
    forgetWhich(type, tit) {
      this.forgetWhichFlag = type
      this.findPswTitle = tit

    },
    reset() {
      this.showSwitch = 9
      console.log(this.showSwitch, 'showSwitch');

      // window.location.href = "tencent://message/?uin=3003533756&Site=wx.qtthtech.com&Menu=yes"
    },
    noLoginGetYzm(mobile, type, area) {        // 找回密码
      if (type == 2 && this.findPswForm.username == '' && mobile == '') {
        this.$message.error('请输入游戏账号和手机号')
        return false;
      } else if (type == 2 && mobile == '') {
        this.$message.error('请输入手机号')
        return false;
      } else if (type == 2 && this.findPswForm.username == '') {
        this.$message.error('请输入游戏账号')
        return false;
      }
      let _this = this
      if (area != "86") {
        // console.log(mobile, 'ddd');
        let area = this.areaCode(mobile)
        if (area) {
          window.nvc.getNVCValAsync(function (nvcVal) {
            axios({
              method: 'post',
              url: '/sms/sendMsg',
              data: {
                area: area,           // 区号
                mobile: mobile,      // 手机号
                type: type,          // 类型
                username: _this.findPswForm.username,      // username
                nvcVal: nvcVal,
              },
            }).then((res) => {
              if (res.data.state == 200) {
                // Messages("success", res.data.message)
                _this.$message.success(res.data.message)
                _this.business_handle(res);
                _this.isSendCodefindPsw = true
                if (type == 2 || type == 7) {
                  _this.timerfindPsw = setInterval(() => {
                    _this.countDown(2)
                  }, 1000);
                }
              } else {
                // Messages("error", res.data.message)
                _this.$message.error(res.data.message)
              }
            }).catch(err => {
              _this.$message.error(err.message)
              // Messages("error", err.message)
            })
          })
        }
      } else {
        if (mobile == '' || !this.isMobile(mobile)) {
          this.$refs.findPswMobile.focus()
          this.$refs.findPswMobile.blur()
        } else {
          window.nvc.getNVCValAsync(function (nvcVal) {
            axios({
              method: 'post',
              url: '/sms/sendMsg',
              data: {
                area: '86',           // 区号
                mobile: mobile,      // 手机号
                type: type,          // 类型
                username: _this.findPswForm.username,      // username
                nvcVal: nvcVal,
              },
            }).then((res) => {
              if (res.data.state == 200) {
                // Messages("success", res.data.message)
                _this.$message.success(res.data.message)
                _this.business_handle(res);
                _this.isSendCodefindPsw = true
                if (type == 2 || type == 7) {
                  _this.timerfindPsw = setInterval(() => {
                    _this.countDown(2)
                  }, 1000);
                }
              } else {
                // Messages("error", res.data.message)
                _this.$message.error(res.data.message)
              }
            }).catch(err => {
              this.$message.error(err.message)
              // Messages("error", err.message)
            })
          })
        }
      }

    },   // 未登录状态 获取手机验证码   type短信类型；1：注册，2：找回密码，2：找回账号

    // 港澳台区号
    areaCode(phoneNumber) {
      // 香港手机号码格式：8位数字，以5、6、8或9开头 
      let hongKongPattern = /^[4|5|6|8|9]\d{7}$/;
      // 澳门手机号码格式：8位数字，以6开头 
      let macauPattern = /^6\d{7}$/;
      // 台湾手机号码格式：10位数字，以09开头 
      let taiwanPattern = /^09\d{8}$/;
      if (hongKongPattern.test(phoneNumber)) {
        return "852";
      }
      else if (macauPattern.test(phoneNumber)) {
        return "853";
      }
      else if (taiwanPattern.test(phoneNumber)) {
        return "886";
      }
      else {
        this.$message.error('不符合港澳台地区手机号码格式')
        return false;
      }
    },
    // 港澳台区改变手机规则
    changeArea(area) {
      if (area == '86') {
        this.findPswFormRules.mobile[1].pattern = /^1[3456789]\d{9}$/
        this.findPswFormRulesUser.mobile[1].pattern = /^1[3456789]\d{9}$/
      } else if (area == '886') {
        this.findPswFormRules.mobile[1].pattern = /^09\d{8}$/;
        this.findPswFormRulesUser.mobile[1].pattern = /^09\d{8}$/;

      } else if (area == '852') {
        this.findPswFormRules.mobile[1].pattern = /^[4|5|6|8|9]\d{7}$/;
        this.findPswFormRulesUser.mobile[1].pattern = /^[4|5|6|8|9]\d{7}$/;
      } else if (area == '853') {
        this.findPswFormRules.mobile[1].pattern = /^6\d{7}$/;
        this.findPswFormRulesUser.mobile[1].pattern = /^6\d{7}$/;
      }
      this.findPswForm.mobile = ''
      this.findPswFormUser.mobile = ''
      // console.log(this.findPswFormRules.mobile[1].pattern,area);
    },

    getCode(mobile, type) {           // 修改手机号(4:原，5：新)，修改密码（3）
      let _this = this
      // Messages('success', '测试')

      if (mobile == '' || !this.isMobile(mobile)) {
        if (type == 3) {
          this.$refs.modifyPswMobile.focus()
          this.$refs.modifyPswMobile.blur()
        } else if (type == 4) {
          this.$refs.yuanMobile.focus()
          this.$refs.yuanMobile.blur()
        } else if (type == 5) {
          this.$refs.newMobile.focus()
          this.$refs.newMobile.blur()
        }
      } else {

        window.nvc.getNVCValAsync(function (nvcVal) {
          let data = {
            username: _this.userInfo.username,      // username
            mobile: mobile,
            type: type,       // 短信类型；1：注册，2：找回密码，3：找回密码 4：修改手机号,5：新绑定手机号，8:修改游戏昵称
            userId: Cookies.get('wx_userId'),
            nvcVal: nvcVal,
          }
          axios({
            method: 'post',
            url: '/sms/sendMsgTouser',
            headers: {
              token: Cookies.get('wx_token'),
            },
            data: data,
          }).then((res) => {
            if (res.data.state == 200) {
              // Messages('success', res.data.message)
              //  this.msg.close()
              _this.$message.success(res.data.message)
              _this.business_handle(res);
              if (type == 3) {
                _this.modifyPswFormIsSendCode = true
                _this.modifyPswFormTimer = setInterval(() => {
                  _this.countDown(3)
                }, 1000);
              } else if (type == 4) {
                _this.isSendCode = true
                _this.timer = setInterval(() => {
                  _this.countDown(4)
                }, 1000);
              } else if (type == 5) {
                _this.isSendCode1 = true
                _this.timer1 = setInterval(() => {
                  _this.countDown(5)
                }, 1000);
              } else if (type == 8) {
                _this.isSendCode1 = true
                _this.timer1 = setInterval(() => {
                  _this.countDown(5)
                }, 1000);
              }
            } else {
              // Messages('error', res.data.message)
              _this.$message.error(res.data.message)
            }
          }).catch(err => {
            _this.$message.error(err.message)
            // Messages('error', err.message)

          })
        })
      }
    },         // 登录状态下 获取验证码       type短信类型；3：修改密码 4：修改手机号,5：新绑定手机号
    submitPsw(findPswForm) {
      // console.log(findPswForm, this.findPswForm);
      this.$refs[findPswForm].validate((valid) => {
        if (valid) {
          let url = ''
          let param = {}
          if (this.forgetWhichFlag == 1) {
            url = '/user/findAccount'
            param = this.findPswFormUser
          } else if (this.forgetWhichFlag == 2) {
            url = '/user/findPwd'
            param = this.findPswForm
          }
          axios.post(url, param).then((res) => {
            if (res.data.state == 200) {
              this.$message.success(res.data.message)
              // Messages('success', res.data.message)
              this.closeMask()
            } else {
              this.$message.error(res.data.message)
              // Messages('error', res.data.message)
            }
          }).catch(err => {
            this.$message.error(err.message)
            // Messages('error', err.message)

          })
        }
      });
    },      // 忘记密码
    submitForm(mobileForm) {
      let _this = this
      this.$refs[mobileForm].validate((valid) => {
        if (valid) {
          axios({
            method: 'post',
            url: '/user/editMobile',
            data: this.mobileForm,
            headers: {
              token: Cookies.get('wx_token'),
            },
          }).then((res) => {
            if (res.data.state == 200) {
              this.$message.success(res.data.message)
              // Messages('success', res.data.message)
              location.reload()
            } else {
              this.$message.error(res.data.message)
              // Messages('error', res.data.message)
            }
          }).catch(err => {
            this.$message.error(err.message)
            // Messages('error', err.message)
          })
        }
      });
    },
    // 实名
    realNameForm(realNameForm) {
      let _this = this
      this.$refs[realNameForm].validate((valid) => {
        if (valid) {
          axios({
            method: 'post',
            url: '/user/doVerify',
            data: {
              realname: this.realName.name,
              idcard: this.realName.nameId,
              platForm: 'web',
              userId: Cookies.get('wx_userId'),
            },
            headers: {
              token: Cookies.get('wx_token'),
            },
          }).then((res) => {
            if (res.data.state == 200) {
              this.$message.success(res.data.message)
              // Messages('success', res.data.message)
              // location.reload()


            } else {
              this.$message.error(res.data.message)
              // Messages('error', res.data.message)
            }
          }).catch(err => {
            this.$message.error(err.message)
            // Messages('error', err.message)
          })
        }
      });
    },

    // 修改手机号
    submitmodifyPsw(modifyPswForm) {
      this.$refs[modifyPswForm].validate((valid) => {
        if (valid) {
          this.modifyPswForm.userId = Cookies.get('wx_userId')
          axios({
            method: 'post',
            url: '/user/editPwd',
            data: this.modifyPswForm,
            headers: {
              token: Cookies.get('wx_token'),
            },
          }).then((res) => {
            if (res.data.state == 200) {
              this.$message.success(res.data.message)
              // Messages('success', res.data.message)
              Cookies.remove('wx_token')
              Cookies.remove('wx_userId')
              localStorage.removeItem('userInfo')
              this.$router.replace('/login')
            } else {
              this.$message.error(res.data.message)
              // Messages('error', res.data.message)

            }
          }).catch(err => {
            this.$message.error(err.message)
            // Messages('error', err.message)
          })
        }
      });
    },
    // 修改游戏昵称
    //  su(){
    //   this.$message({
    //     type: 'success',
    //     message: '修改成功!',
    //     duration:0
    //   })
    //  },
    submitmodifyNickname(modifyPswForm) {
      // this.$message.success(res.data.message)

      this.$refs[modifyPswForm].validate((valid) => {
        if (valid) {
          this.modifyNicknameForm.userId = Cookies.get('wx_userId')
          axios({
            method: 'post',
            url: '/user/editUsernick',
            data: this.modifyNicknameForm,
            headers: {
              token: Cookies.get('wx_token'),
            },
          }).then((res) => {
            if (res.data.state == 200) {
              this.$message.success(res.data.message)
              // Messages('success', res.data.message)
              setTimeout(() => { location.reload() }, 2000)

            } else {
              this.$message.error(res.data.message)
              // Messages('error', res.data.message)
            }
          }).catch(err => {
            this.$message.error(err.message)
            // Messages('error', err.message)
          })
        }
      });
    },
    // 修改密码
    countDown(type) {
      if (type == 2) {
        if (this.timerCountfindPsw == 0) {
          this.timerCountfindPsw = 60
          this.isSendCodefindPsw = false
          this.yzmTxtFindPsw = '重新获取'
          window.clearInterval(this.timerfindPsw); // 清除定时器
          this.timerfindPsw = null;
        } else {
          this.timerCountfindPsw--
        }
      }          // 忘记密码，找回密码
      else if (type == 3) {
        if (this.modifyPswFormTimerCount == 0) {
          this.modifyPswFormTimerCount = 60
          this.modifyPswFormIsSendCode = false
          this.modifyPswFormYzmTxt = '重新获取'
          window.clearInterval(this.modifyPswFormTimer); // 清除定时器
          this.modifyPswFormTimer = null;
        } else {
          this.modifyPswFormTimerCount--
        }
      }     // 修改密码
      else if (type == 4) {
        if (this.timerCount == 0) {
          this.timerCount = 60
          this.isSendCode = false
          this.yzmTxtYuanMobile = '重新获取'
          window.clearInterval(this.timer); // 清除定时器
          this.timer = null;
        } else {
          this.timerCount--
        }
      }     // 修改手机号 - 原手机号
      else if (type == 5) {
        if (this.timerCount1 == 0) {
          this.timerCount1 = 60
          this.isSendCode1 = false
          this.yzmTxtNewMobile = '重新获取'
          window.clearInterval(this.timer1); // 清除定时器
          this.timer1 = null;
        } else {
          this.timerCount1--
        }
      }     // 修改手机号 - 新手机号
    },
    // 倒计时
    isMobile(mobile) {         // 验证是否是手机号
      // if (/^1[3456789]\d{9}$/.test(mobile)) {
      if (this.modifyPswFormRules.mobile[1].pattern.test(mobile)) {
        return true
      } else {
        return false
      }
    },             // 验证手机号（用于发送验证码时对手机号的校验）
    closeMask() {
      // console.log("closeMask")
      eventBus.$emit('closeMask')
      this.isShowMask = false

      if (this.showSwitch == 6) {
        this.$router.go(-1)
      }
    },                  // 关闭弹窗

    /* 阿里无痕验证 */
    init() {
      let _this = this
      var appKey = 'FFFF0N1N00000000A7D3'
      var scene = 'nvc_message'

      // 实例化nvc 对无痕验证进行初始化操作
      AWSC.use("nvc", function (state, module) {
        // 初始化 调用module.init进行初始化
        window.nvc = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: appKey,
          //使用场景标识。它和应用类型标识（appkey字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: scene,
          // 二次验证获取人机信息串，跟随业务请求一起上传至业务服务器，由业务服务器进行验签。
          success: function (data) {
            console.log(111);
            window.console && console.log(data)
            _this.verificationData(data, 'nc');
          },
          // 前端二次验证失败时触发该回调参数
          fail: function (failCode) {
            console.log(222);
            window.console && console.log("fail:" + failCode)
          },
          // 前端二次验证加载异常时触发该回调参数。
          error: function (errorCode) {
            console.log(666);
            window.console && console.log("error:" + errorCode)
          }
        });
        console.log(window.nvc);
      });
    },
    verificationData(data, type) { },
    business_handle(data) {
      // 业务服务器请求回调控制是否需要二次验证
      if (data.code == '100' || data.code == '200') {
        // 无痕验证通过
        console.log("验证通过!");
        // 去注册或登录
      } else if (data.code == '800' || data.code == '900') {
        // 无痕验证失败，直接拦截
        console.log("验证失败!");
        // 跳转到账号密码页
        var ncoption = {
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "nc",
          // 二次验证文案配置
          upLang: {
            'cn': {
              //加载状态提示。
              'LOADING': "加载中...",
              //等待滑动状态提示。
              'SLIDE': "请向右滑动验证",
              //验证通过状态提示。
              'SUCCESS': "验证通过",
              //验证失败触发拦截状态提示。
              'ERROR': "非常抱歉，网络出错了...",
              //验证失败触发拦截状态提示。
              'FAIL': "验证失败，请重试"
            }
          }
        }
        // 唤醒二次验证（滑动验证码）
        window.nvc.getNC(ncoption);
      } else if (data.code == '400') {
        // 无痕验证失败，触发二次验证
        // 二次验证码（滑动验证码）配置项设置，详情请见滑动验证集成方式文档
        // 二次验证的appkey，scene，test值及success，fail，error的回调由nvc初始化时决定，请不要在二次验证时传入
        var ncoption = {
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "nc",
          // 二次验证文案配置
          upLang: {
            'cn': {
              //加载状态提示。
              'LOADING': "加载中...",
              //等待滑动状态提示。
              'SLIDE': "请向右滑动验证",
              //验证通过状态提示。
              'SUCCESS': "验证通过",
              //验证失败触发拦截状态提示。
              'ERROR': "非常抱歉，网络出错了...",
              //验证失败触发拦截状态提示。
              'FAIL': "验证失败，请重试"
            }
          }
        }
        // 唤醒二次验证（滑动验证码）
        window.nvc.getNC(ncoption);
      }
    },    // 处理业务返回结果：人机信息串上传接口的回调函数，通过业务服务端的返回结果，控制无痕验证的不同状态。
  },
  watch: {
    propShowSwitch(newVal) {
      this.showSwitch = newVal
    },
    yuanMobileProp(newVal) {
      this.mobileForm.mobile = newVal
    },
  },
};
</script>

<style lang="less" scoped>
.vip {
  line-height: 20px;

  .tit {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .txt1 {
    margin-top: 15px;
    display: flex;

    span {
      font-size: 16px;
    }

    .num {
      min-width: 20px;
    }
  }

  .txt2 {
    font-size: 16px;
    padding-left: 20px;
  }

  .txt3 {
    padding-left: 30px;
  }
}

.tipCopywriting {
  .tipCopywritingMain {
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    color: #333333;
    font-weight: 700;
    line-height: 36px;
  }

  .submitWrap {
    display: flex;
    justify-content: center;

    .btnWrap {
      margin: 0 10px;
      display: inline-block;
      padding: 1px;
      width: 120px;
      height: 44px;
      line-height: 42px;
      background: linear-gradient(to right, #FFE3E3, #AA97FF);
      box-sizing: border-box;
      border-radius: 44px;
      overflow: hidden;

      span {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #DD8EFF, #FFA3F0);
        border-radius: 46px;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .cancel {
      background: #FCA1F2;

      span {
        color: #FCA1F2;
        background: #ffffff;
      }
    }
  }
}
</style>
<style lang="less">
.pubMask {
  .el-dialog {
    min-width: 560px;
    background: transparent;

    .codeWrap {
      .codeBar {
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: left;

        .el-input__inner {
          width: 132px;
        }

        .sendBtn {
          display: inline-block;
          width: 77px;
          min-width: 77px;
          text-align: center;
          height: 36px;
          font-size: 12px;
          color: #ffffff;
          cursor: pointer;
          background: #333333;
        }
      }
    }

    .submitWrapBar {
      margin-bottom: 0 !important;

      .el-form-item__content {
        margin-left: 0 !important;
      }

      .submitWrap {
        display: flex;
        justify-content: center;

        .btnWrap {
          margin: 0 10px;
          display: inline-block;
          padding: 1px;
          width: 120px;
          height: 44px;
          line-height: 42px;
          background: linear-gradient(to right, #FFE3E3, #AA97FF);
          box-sizing: border-box;
          border-radius: 44px;
          overflow: hidden;

          span {
            display: inline-block;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #DD8EFF, #FFA3F0);
            border-radius: 46px;
            font-size: 18px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
          }
        }

        .cancel {
          background: #FCA1F2;

          span {
            color: #FCA1F2;
            background: #ffffff;
          }
        }
      }
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 5px;
      background: linear-gradient(#D7B7FF, #F9F8FF);
      border-radius: 10px;
    }

    .maskMain {
      position: relative;
      z-index: 1;
      padding: 60px 100px;
      background: linear-gradient(#F6F1FF, #D7C5FF);
      border-radius: 10px;
    }

    .closed {
      position: absolute;
      right: -35px;
      top: -38px;
      z-index: 1;
      cursor: pointer;
    }

    .title {
      margin-bottom: 20px;
      text-align: center;
      font-size: 30px;
      color: #333333;
      font-weight: 700;
    }

    .forgetWrap {
      padding-top: 30px;
      text-align: center;

      .forgetItem {
        display: inline-block;
        text-align: center;
        margin-bottom: 20px;
        width: 300px;
        height: 50px;
        line-height: 50px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 22px;
        font-weight: 700;
        color: #333333;
        cursor: pointer;
      }
    }

    .el-form-item__label {
      width: 120px;
      font-size: 18px;
      color: #333333;
      font-weight: 700;
      line-height: 36px;

      &:before {
        display: none;
      }
    }

    .el-form-item__content {
      line-height: 36px;
    }

    .el-form-item {
      margin-bottom: 18px !important;
    }

    .el-input {
      height: 36px;
      line-height: 36px;
    }

    .el-input__inner {
      width: 220px;
      height: 36px;
      background: transparent;
      border: 1px solid #333333;
      border-radius: 0;
      padding: 0 6px;

      &::placeholder {
        font-size: 12px;
      }
    }

    .el-input.is-disabled {
      .el-input__inner {
        color: #333333;
      }
    }

    .telWrap {
      .Sel {
        .el-input__inner {
          border-right: none;
        }
      }

      .el-input__inner {
        width: 100% !important;
      }

    }

  }
}
</style>
